@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  font-size: 16px;
}

body {
  font-family: 'Roboto', sans-serif;
}

* {
  box-sizing: border-box;
  line-height: 2;
}

a {
  cursor: pointer;
  transition: all 0.3s;
  text-decoration: none;
}

a:hover {
  opacity: 0.5;
}

/* - - - - - - - - - - - - - - - - - - - - 
 * ハンバーガーメニュー 
 * - - - - - - - - - - - - - - - - - - - - */

#ham_btn {
  position: fixed;
  top: 16px;
  right: 24px;
  width: 46px;
  height: 26px;
  cursor: pointer;
  z-index: 100;
}
#ham_btn span {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  transition: all 0.3s;
}

#ham_btn span:nth-child(1) {
  top: 0;
}
#ham_btn span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}
#ham_btn span:nth-child(3) {
  bottom: 0;
}

#ham_btn .message {
  text-align: center;
  font-size: 12px;
  color: #fff;
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* 開いている時 */
#ham_btn.open span:nth-child(1) {
  top: 50%;
  transform: translateY(-50%) rotate(30deg);
}

#ham_btn.open span:nth-child(2) {
  opacity: 0;
}

#ham_btn.open span:nth-child(3) {
  bottom: 50%;
  transform: translateY(50%) rotate(-30deg);
}

@media (min-width: 1024px) {
  #ham_btn {
    display: none;
  }
}

@media (max-width: 640px) {
  #ham_btn {
    right: 16px;
  }
}
